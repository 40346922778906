.mainCointainer {
  width: 100vw;
  display: flex;
  padding: 2vmax;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  .image {
    padding: 1vmax;
    width: 50%;
    img {
      width: 100%;
    }
  }
  .text {
    width: 50%;
    padding: 1vmax 2vmax;
    line-height: 2.5rem;
  }
}
@media screen and (max-width: 600px){
  .mainCointainer{
    flex-direction: column;
    width: 100%;
    padding: 1vmax;
    .image, .text {
      width: 100%;
    }
  }
}
