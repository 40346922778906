.contactForm {
  background-color: #f0fff1;
  width: 35vw;
  min-height: 70vh;
  box-sizing: border-box;
  overflow: hidden;
  padding: 1vmax;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  margin: 0 auto;
  margin-top: 84px;
  .header {
    padding: 1vmax;
    border-bottom: 1px solid gray;
    margin-bottom: 2vmax;
    font-size: 2.2rem;
  }
  form {
    display: flex;
    flex-direction: column;
    padding: 0 2vmax;
    justify-content: center;
    align-items: center;
    width: 100%;
    .input {
      display: flex;
      width: 100%;
      align-items: center;
      margin: 1vmax 0;
      input,
      textarea {
        padding: 1vmax 4vmax;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid rgba(0, 0, 0, 0.267);
        border-radius: 4px;
        outline: none;
      }
      svg {
        position: absolute;
        transform: translateX(1vmax);
        font-size: 1.6vmax;
        color: rgba(0, 0, 0, 0.623);
      }
      textarea {
        padding: 1vmax;
        height: 15vmax;
      }
    }
    .sendBtn {
      font-weight: bold;
      letter-spacing: 2px;
      border: none;
      background-color: rgb(129, 255, 71);
      width: 100%;
      padding: 0.8vmax;
      cursor: pointer;
      transition: all 0.5s;
      border-radius: 4px;
      outline: none;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.219);
      &:hover {
        background-color: tomato;
        color: white;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .contactForm {
    width: 100%;
    height: 100%;
  }
}
