.homeContainer {
  max-width: 1600px;
  margin: 0 auto;
  margin-top: 83px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .title {
    padding: 1.2rem;
    font-size: 2rem;
    font-weight: bold;
    border-bottom: 1px solid rgb(180, 180, 180);
  }
  .service{
    perspective: 2000px;
  }
  .service,
  .about {
    padding: 1rem;
    width: 85vw;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}

@media screen and (max-width: 600px) {
  .homeContainer {
    .slider{
      display: none;
    }
    .service,
    .about {
      flex-direction: column;
      width: 100%;
    }
  }
}
