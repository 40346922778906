.bookServiceFormMain {
  padding: 1vmax;
  h2 {
    font-size: 1.7rem;
    margin: 5px 0;
  }
  input, textarea {
    width: 80%;
    height: 2.5vmax;
    padding: 5px 10px;
    margin: 0.5vmax 0;
    border: none;
    &:focus{
        outline: 1px solid rgb(185, 184, 184);
    }
  }
  textarea{
      height: 5vmax;
  }

}
@media screen and (max-width: 600px){
  .bookServiceFormMain{
    input, textarea {
      width: 100%;
      padding: 2rem;
    }
    textarea{
      height: 6rem;
  }
  }
}
