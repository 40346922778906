.admin-account-view {
  margin: 0 auto;
  margin-top: 110px;
  max-width: 1800px;
  padding: 1rem;
  min-height: 58vh;
  h1 {
    padding: 1rem;
    text-align: center;
  }
  .search-filter {
    display: flex;
    align-content: center;
    justify-content: space-between;
    margin: 1rem 0;
    flex-wrap: wrap;
    align-items: center;
    .select-date {
      display: flex;
      align-content: center;
      justify-content: flex-start;
      height: 35px;
      input {
        margin-right: 1rem;
        outline: none;
        padding: 0 1rem;
      }
    }
    .text-input {
      display: flex;
      flex-grow: 1;
      margin: 0 1rem;
      max-width: 60%;
      height: 35px;
      input {
        margin-right: 1rem;
        width: 100%;
        outline: none;
        padding: 0 1rem;
      }
    }
    .submit-button {
      button {
        padding: 0 20px;
        margin-left: 10px;
        height: 35px;
        border: none;
        background-color: #5d4157;
        color: white;
        &:hover {
          background-color: #1e8134;
        }
      }
    }
  }
  table {
    width: 100%;
    tbody {
      margin-bottom: 0.5rem;
    }
    th {
      text-transform: uppercase;
      background-color: #aaaaad;
      text-align: center;
    }
    th,
    td {
      border: 1px solid #2640c2;
      padding: 7px;
    }
  }
  .paginationBttns {
    height: 40px;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 1rem 0;
    color: white;
    .paginationDisabled a {
      display: none;
    }
    .paginationActive a {
      color: white;
      background-color: #2b2eff;
    }
    a {
      padding: 10px;
      margin: 8px;
      border-radius: 5px;
      border: 1px solid #2b2eff;
      cursor: pointer;
      text-decoration: none;
      &:hover {
        background-color: #2b2eff;
        color: white;
      }
    }
  }
}