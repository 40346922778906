.admin-client-areas {
  margin: 0 auto;
  margin-top: 90px;
  max-width: 1500px;
  padding: 1rem;
  min-height: 64vh;
  h1 {
    padding: 2rem;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
  }
  .search-filter {
    display: flex;
    align-content: center;
    justify-content: space-between;
    margin: 1rem 0;
    flex-wrap: wrap;
    align-items: center;
    .text-input {
      display: flex;
      flex-grow: 1;
      max-width: 90%;
      height: 35px;
      input {
        margin-right: 1rem;
        width: 100%;
        outline: none;
        padding: 0 1rem;
      }
    }
    .submit-button {
      button {
        padding: 0 20px;
        margin-left: 10px;
        height: 35px;
        border: none;
        background-color: #5d4157;
        color: white;
        &:hover {
          background-color: #1e8134;
        }
      }
    }
  }
  table {
    width: 100%;
    tbody {
      margin-bottom: 0.5rem;
      td {
        button {
          border: none;
          background-color: transparent;
          outline: none;
        }
      }
    }
    th {
      text-transform: uppercase;
      background-color: #aaaaad;
      text-align: center;
    }
    th,
    td {
      border: 1px solid #2640c2;
      padding: 7px;
    }
  }
}