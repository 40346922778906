.partnerDashboard {
  .header{
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    padding: 1rem;
    margin-bottom: 2rem;
  }
  max-width: 95vw;
  margin: 0 auto;
  padding: 0.5vmax 1.5vmax;
  margin-top: 84px;
  .menuList {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin: 0.5vmax 0;
    a,
    span {
      margin-left: 2vmax;
      padding: 0.5vmax 1.5vmax;
      background-color: #c4ebce;
      border: none;
      outline: none;
      border-radius: 5px;
      font-weight: bold;
      &:hover {
        transform: translateY(-0.2vmax);
        transition: all 0.3zs;
      }
    }
    a {
      &:hover {
        background-color: rgb(126, 255, 126);
      }
    }
    span {
      &:hover {
        background-color: rgb(253, 239, 40);
      }
    }
  }
  .generalOrderTable {
    background-color: #ebe5d7;
    margin-bottom: 1vmax;
    .general-job-table-title {
      padding: 1rem 0;
      font-weight: bold;
      text-align: center;
    }
    table {
      text-align: center;
      thead tr > th {
        background-color: #fffaee;
        padding: 1rem 0;
        border: none;
      }
      tbody tr {
        td {
          border: 1px solid #6868ca94;
          button {
            border: none;
            padding: 5px;
            background: linear-gradient(to right, #776cc8, #1135c0);
            color: white;
            width: 100%;
          }
        }
      }
    }
  }
  .pastOrderTable1{
    display: none;
  }
  .pastOrderTable {
    background-color: #ffffff;
    margin-bottom: 1vmax;
    .pastorder-table-title {
      padding: 1rem 0;
      text-align: center;
      font-weight: bold;
    }
    table {
      text-align: center;
      thead tr > th {
        background-color: #d9ffc7;
        padding: 1rem 0;
        border: none;
      }
      tbody tr {
        td {
          border: 1px solid #6868ca94;
          input {
            min-height: 30px;
            text-align: center;
            border: none;
            outline: none;
            width: 120px;
          }
          button {
            border: none;
            padding: 7px 0;
            background: linear-gradient(to right, #776cc8, #1135c0);
            color: white;
            width: 100%;
          }
        }
      }
    }
  }

  .general-job-calender {
      background-color: whitesmoke;
    .calender-title {
      text-align: center;
      background-color: #cbcfee;
      padding: 1.5rem;
    }
  }
  .rbc-toolbar {
    margin: 0;
    .rbc-btn-group {
      button {
        padding: 1rem 3.2rem;
        // margin: .2rem;
        border-radius: 0;
      }
    }
  }
  .rbc-month-view{
    .rbc-header{
      padding: 1rem .3rem;
    }
  }
  .rbc-today{
    background-color: #90ee9091;
  }
}

@media screen and (max-width: 600px){
  
}
