.authContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  .authBox {
    margin: 125px 0px 25px 0px;
    border-radius: 10px;
    background-color: rgb(240, 255, 241);
    width: 350px;
    height: 620px;
    overflow: hidden;
    padding: 1rem 1.5rem;
    div {
      .authToggle {
        display: flex;
        height: 3rem;
        p {
          color: rgba(0, 0, 0, 0.678);
          transition: all 0.5s;
          cursor: pointer;
          display: grid;
          place-items: center;
          width: 100%;
          &:hover {
            color: tomato;
          }
        }
      }
      button {
        background-color: tomato;
        height: 3px;
        width: 50%;
        border: none;
        transition: all 0.5s;
        box-shadow: none;
      }
    }
    .google-login {
      width: 100%;
      margin: 2rem 0;
      padding: 2rem;
      justify-content: center;
    }
    .loginForm,
    .signUpForm {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: auto;
      justify-content: space-evenly;
      height: 80%;
      transition: all 0.5s;
      div {
        display: flex;
        width: 100%;
        align-items: center;
        input {
          padding: 1rem 4rem;
          padding-right: 1rem;
          width: 100%;
          box-sizing: border-box;
          border: 1px solid rgba(0, 0, 0, 0.267);
          border-radius: 4px;
          outline: none;
        }
        svg {
          position: absolute;
          transform: translateX(1rem);
          font-size: 1.7rem;
          color: rgba(0, 0, 0, 0.623);
        }
      }
      .loginBtn,
      .signUpBtn {
        border: none;
        background-color: tomato;
        color: white;
        width: 100%;
        padding: 1rem;
        cursor: pointer;
        transition: all 0.5s;
        border-radius: 4px;
        outline: none;
        &:hover {
          background-color: rgb(179, 66, 46);
        }
      }
    }
    .loginForm {
      a {
        color: rgba(0, 0, 0, 0.651);
        text-decoration: none;
        align-self: flex-end;
        transition: all 0.5s;
        &:hover {
          color: black;
        }
      }
    }
    .signUpForm {
      transform: translateY(-100%) translateX(-100rem);
      .registerImage {
        img {
          width: 5rem;
          border-radius: 100%;
        }
        input {
          display: flex;
          padding: 0%;
          margin-left: 20px;
          &::file-selector-button {
            cursor: pointer;
            width: 100%;
            z-index: 2;
            height: 5vh;
            border: none;
            margin: 0%;
            transition: all 0.5s;
            padding: 0 1rem;
            color: rgba(0, 0, 0, 0.623);
            background-color: rgb(255, 255, 255);
            &:hover {
              background-color: rgb(235, 235, 235);
            }
          }
        }
      }
    }
    .shiftToNeutral {
      transform: translateX(0%);
    }
    .shiftToLeft {
      transform: translateX(-100%);
      padding: 0 2rem;
    }
    .shiftToNeutralForm {
      transform: translateX(0%) translateY(-100%);
      padding: 0;
    }
    .shiftToRight {
      transform: translateX(100%);
    }
  }
}

@media screen and (max-width: 600px) {
  .authContainer {
    padding: 1rem;
    margin: 0;
    .authBox {
      width: 100vw;
      padding: 1rem;
      div {
        .authToggle {
          p {
            font-size: 1.6rem;
          }
        }
      }
      .google-login {
        width: 100%;
        margin: 2rem 0;
      }
      .loginForm,
      .signUpForm {
        div {
          input {
            padding: 1.5rem 5rem;
            font: 1.6rem cursive;
          }
          svg {
            font-size: 2.8rem;
          }
        }
        .loginBtn,
        .signUpBtn {
          font: 1.6rem "Roboto";
          padding: 1.8rem;
        }
      }
      .loginForm {
        a {
          font: 500 1.8rem "Gill Sans";
        }
      }
      .signUpForm {
        .registerImage {
          img {
            width: 8rem;
            border-radius: 100%;
          }
          input {
            &::file-selector-button {
              height: 7vh;
              font: 400 1.8rem cursive;
            }
          }
        }
      }
    }
    .loginBtn,
    .signUpBtn {
      font: 300 1.9rem "Roboto";
      padding: 1.8rem;
    }
  }
}
