.serviceCard {
  width: 20vw;
  display: flex;
  flex-direction: column;
  background-color: rgb(218, 211, 211);
  color: rgb(48, 48, 48);
  margin: 2vmax 0;
  transition: all 0.5s;
  transform: rotateX(40deg);
  &:hover {
    box-shadow: 0 0 5px rgba(15, 15, 15, 0.26);
    transform: translateY(-1vmax) scale(1.1);
  }
  img {
    width: 100%;
  }
  h1 {
    text-align: center;
    padding: 1rem;
    font-size: 2rem;
  }
  p {
    font-family: "Roboto";
    line-height: 2.5rem;
    padding: 2rem;
  }
}
@media screen and (max-width: 600px) {
  .serviceCard {
    width: 100%;
    transform: none;
  }
}
