.pricingContainer {
  max-width: 1400px;
  margin: 83px auto;
  margin-bottom: 0;

  .pricesHeading {
    margin: 0 auto;
    max-width: 30vmax;
    padding: 1vmax;
    text-align: center;
    font-size: 2vmax;
    border-bottom: 1px solid gray;
  }
  .packages {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: stretch;
    justify-content: space-evenly;
  }
}
@media screen and (max-width: 600px){
  
}
