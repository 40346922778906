.main {
  max-width: 1400px;
  margin: 83px auto;
  margin-bottom: 0;
  background-color: rgb(237, 243, 243);
  .title {
    margin: 40px auto;
    max-width: 30rem;
    padding: 1rem;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    border-bottom: 1px solid gray;
  }
  h2 {
    text-align: center;
    font-size: 3rem;
    padding: 2rem;
    font-weight: bold;
  }
  .pageContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: center;

    .serviceInfo {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      flex-wrap: wrap;
      width: 55%;
    }
    .package {
      width: 40%;
      display: flex;
      align-content: flex-start;
      flex-direction: column;
      align-items: center;
    }
  }
}
@media screen and (max-width: 600px) {
  .main {
    .pageContent {
      flex-direction: column;
      .serviceInfo {
        width: 100%;
      }
      .package {
        width: 100%;
        padding: 1rem;
      }
    }
  }
}
