.print-main {
  margin: 0 auto;
  margin-top: 84px;
  width: 210mm;
  text-align: center;
  padding: 1rem;
  .btn {
    margin: 2rem 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    button {
      padding: 1rem 3rem;
      background-color: rgb(95, 104, 101);
      font-size: 1.8rem;
      border: none;
      border-radius: 5px;
      &:hover {
        background-color: rgb(0, 226, 0);
        outline: none;
      }
    }
  }
}

.invoice-pdf {
  position: relative;
  padding: 1rem;
  background-color: white;
  text-align: left;
  .logo {
    width: 100%;
    text-align: center;
    margin: 1rem 0;
    img {
      width: 200px;
    }
  }
  .invoice-from {
    margin: 20px;
    .invoice {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .invoice-details {
    margin: 2rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .invoice-to {
      width: 50%;
    }
    .invoice-info {
      width: 35%;
    }
  }
  table {
    thead,
    tbody {
      text-align: center;
      th,
      td {
        border: 1px solid #383636;
        padding: 1rem;
        p {
          text-align: right;
        }
      }
    }
    tfoot {
      tr,
      td {
        text-align: right;
        border: 1px solid #383636;
        padding: 1rem;
        h3 {
          font-weight: bold;
        }
      }
    }
  }
  footer {
    font-size: 1.4rem;
    padding: 1rem;
    margin-top: 3rem;
    text-align: center;
    h3 {
      margin-bottom: 3rem;
    }
    h4 {
      position: absolute;
      color: red;
      bottom: 10px;
      left: 0;
      right: 0;
    }
  }
  p,
  a,
  h2,
  h3 {
    line-height: 2.5rem;
    color: black;
  }
}
