.container {
  margin: 0 auto;
  margin-top: 84px;
  min-height: 64vh;
  .heading {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    padding: 2rem;
  }
  .jobdetails-body {
    margin: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .info {
      margin: 1rem;
      padding: 1rem;
      width: 25%;
      .field {
        padding: 1rem;
      }
    }
    .job-addresses {
      margin: 1rem;
      display: flex;
      flex-grow: 1;
      flex-wrap: wrap;
      justify-content: space-around;
      .address-field {
        padding: 1rem;
        h2,
        p {
          padding: 1rem;
        }
        a {
          color: -webkit-link;
        }
      }
    }
  }
  .btn {
    text-align: center;
    margin: 1rem;
    button {
      padding: 1rem 3rem;
      background-color: rgb(95, 104, 101);
      font-size: 1.8rem;
      border: none;
      border-radius: 5px;
      color: white;
      &:hover {
        background-color: rgb(0, 226, 0);
        outline: none;
        color: black;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .container {
    margin-top: 220px;
    width: 100%;
    .jobdetails-body {
      .info,
      .job-addresses {
        width: 100%;
        margin: 1rem 0;
        padding: 0;
      }
      .job-addresses {
        .address-field {
          width: 100%;
          padding: 0;
          margin: 1rem 0;
          background-color: rgb(244, 247, 206);
        }
      }
    }
  }
}
