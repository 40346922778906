.client-dashboard {
  margin-top: 84px;
  .heading {
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    padding: 1rem;
  }
  .body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
    margin: 1rem 0;
    .profile {
      display: flex;
      padding: 1rem;
      width: 50%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 150px;
        border-radius: 100%;
        transition: all 0.5s;
        margin: 1rem 0;
        outline: none;
        &:hover {
          transform: scale(1.05);
          outline: none;
        }
      }
      h4 {
        padding: 1rem;
      }
      .btn {
        margin: 2rem 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        button {
          padding: 1rem 3rem;
          background-color: rgb(95, 104, 101);
          font-size: 1.8rem;
          border: none;
          border-radius: 5px;
          &:hover {
            background-color: rgb(0, 226, 0);
            outline: none;
          }
        }
      }
    }
    .client-orders {
      display: flex;
      padding: 1rem 2rem;
      width: 50%;
      flex-direction: column;
      justify-content: space-evenly;
      .client-orders-buttons {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        width: 100%;
        button {
          margin: 3rem 0;
          padding: 1rem 3rem;
          background-color: rgb(95, 104, 101);
          font-size: 1.8rem;
          border: none;
          border-radius: 5px;
          width: 100%;
          &:hover {
            background-color: rgb(0, 226, 0);
            outline: none;
          }
        }
      }
    }
  }
  .client-order-tables {
    table {
      text-align: center;
      padding: 1rem;
      margin-bottom: 2rem;
      caption{
        font-size: 2rem;
        padding: 2rem;
        margin-bottom: 1rem;
        color: tomato;
      }
      thead tr > th {
        background-color: #fffaee;
        padding: 1rem 0;
        border: none;
      }
      tbody tr {
        td {
          border: 1px solid #6868ca94;
          button {
            border: none;
            padding: 5px;
            background: linear-gradient(to right, #776cc8, #1135c0);
            color: white;
            width: 100%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .client-dashboard{
    .body{
      .profile, .client-orders{
        width: 100%;
      }
    }
  }
}
