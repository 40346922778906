@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

:root {
  font-size: 62.5%;
  // background-color: #f5f3ee;
  // background: linear-gradient(to top left, #84d577, #d1d3cabd);
  background: linear-gradient(to top left, #b9ddb399, #d1d3cabd)
}

* {
  // max-width: 1600px;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  font-size: 1.3rem;
  scroll-behavior: smooth;
}
a {
  color: black;
  text-decoration: none;
}
@media screen and (max-width: 600px) {
  :root {
    background: none;
    background-color: #f5f3ee;
  }
  * {
    font-size: 1.5rem;
  }
}
