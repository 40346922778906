.bill-main {
  border: 1px solid rgba(207, 207, 207, 0.589);
  width: 40rem;
  display: flex;
  margin: 2vmax 0;
  flex-direction: column;
  align-items: center;
  line-height: 3rem;
  .bill-title {
    padding: 2rem;
    font-size: 2rem;
    border-bottom: 1px solid lightgrey;
  }
  .bill-content {
    padding: 1vmax;
    width: 90%;
    margin: 1vmax 0;
    .bill-content-field {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
    }
  }
  .bill-type {
    width: 100%;
    text-align: center;
    background-color: azure;
  }
}
@media screen and (max-width: 600px){
  .bill-main{
    width: 100%;
  }
}
