.global-error {
  background: #f3eb7f;
  width: 100%;
  position: absolute;
  top: 20px;
  left: 0;
  z-index: -1;
  padding: 10px;
  transition: top 0.2s;
  text-align: center;
  span {
    color: red;
    svg {
      margin-right: 5px;
    }
  }
}
.slide-in {
  top: 84px;
  z-index: 1;
}
.not-found-page {
  margin: 20px auto;
  margin-top: 110px;
}
