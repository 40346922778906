.pastorderContainer {
  margin-top: 84px;
  min-height: 60vh;
  .accept,
  .complete {
    .header {
      width: 300px;
      margin: 1rem auto;
      padding: 1rem;
      text-align: center;
      border-bottom: 1px solid black;
      font-size: 2.2rem;
      font-weight: bold;
    }
    table {
      text-align: center;
      margin-bottom: 5rem;
      thead tr > th {
        background-color: #fffaee;
        padding: 1rem 0;
        border: none;
      }
      tbody tr {
        td {
          border: 1px solid #6868ca94;
          button {
            border: none;
            padding: 5px;
            background: linear-gradient(to right, #776cc8, #1135c0);
            color: white;
            width: 100%;
          }
        }
      }
    }
  }
  .accept {
    background-color: #c9ecb8;
  }
  .complete {
    background-color: #b8c6ec;
  }
}
