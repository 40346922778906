.mainContainer{
  margin-top: 84px;
  text-align: center;
  min-height: 63.9vh;
  .heading{
    padding: 1rem;
    margin-bottom: 1rem;
    img{
      width: 200px;
    }
  }
  .body{
    padding: 1rem;
    h1{
      font-size: 2.5rem;
      padding: 1rem;
    }
    p{
      padding: .5rem;
      font-size: 1.8rem;
    }
  }
}