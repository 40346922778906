.navbarContainer {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  flex-wrap: wrap;
  // background-color: rgb(248, 255, 240);
  background: linear-gradient(to top left, #dad3d3, #7dd56f);
  padding: 0 1.5vmax;
  font-size: 1.6vmax;
  font-weight: bold;
  position: fixed;
  top: 0;
  z-index: 99;
  .image {
    flex-grow: 1;
    padding: 1rem;
    img {
      width: 60px;
    }
  }
  .menueItem {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    a {
      margin: 0 1.2rem;
      // background-color: #cdcdcdea;
      padding: 10px;
      border-radius: 15px;
      &:hover {
        background-color: rgb(255, 247, 213);
        transform: scale(1.2) translateY(-0.2rem);
        transition: all 0.2s ease-out;
      }
    }
    span {
      margin: 1rem;
      padding: 2px;
      // background-color: #cdcdcdea;
      border-radius: 15px;
      &:hover {
        background-color: rgb(224, 48, 48);
        color: white;
        transform: scale(1.2) translateY(-0.2rem);
        transition: all 0.2s ease-out;
      }
      .MuiSvgIcon-root {
        font-size: 2.2rem;
        width: 3em;
      }
    }
  }
}
.mobileMenuContainer {
  display: none;
}

@media screen and (max-width: 600px) {
  .navbarContainer {
    display: none;
    .image {
      flex-grow: 0;
    }
    .menueItem {
      width: 75%;
      justify-content: space-between;
    }
  }
  .mobileMenuContainer {
    display: block;
  }
}
