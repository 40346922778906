.profileContainer {
  display: flex;
  padding: 0 1.5vmax;
  margin-top: 84px;
  .partnerProfile {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 20vmax;
      border-radius: 10%;
      transition: all 0.5s;
      margin: 1vmax 0;
      outline: none;
      &:hover {
        transform: scale(1.05);
        outline: none;
      }
    }
    h4 {
      padding: 1vmax;
    }
  }
  .details {
    width: 60%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    div {
      display: flex;
      min-width: 30%;
      padding: 1vmax;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }
  }
}
@media screen and (max-width: 600px) {
}
