.animationContainer {
  margin-top: 84px;
  height: 55vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto", sans-serif;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(94, 23, 98, 1) 73%,
    rgba(138, 82, 141, 1) 100%
  );
  position: relative;
  perspective: 100px;
  overflow: hidden;
  .sun {
        display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: rgb(246, 255, 0);
    background: linear-gradient(
      180deg,
      rgba(246, 255, 0, 1) 0%,
      rgba(169, 0, 46, 1) 73%,
      rgba(99, 0, 16, 1) 100%
    );
    position: absolute;
    right: 100px;
    top: 50px;
    animation: sun 1s alternate infinite;
    span{
      color: white;
      font-weight: bold;
    }
  }
  @keyframes sun {
    from {
      box-shadow: 0 0 50px tomato;
    }
    to {
      box-shadow: 0 0 50px gold;
    }
  }
  .mountain,
  .mountain1,
  .mountain2 {
    background-color: black;
    clip-path: polygon(
      0% 100%,
      4% 85%,
      10% 74%,
      19% 62%,
      28% 41%,
      41% 30%,
      50% 30%,
      61% 28%,
      67% 36%,
      73% 45%,
      81% 59%,
      98% 80%,
      100% 100%
    );
    position: absolute;
    bottom: 18vh;
  }
  .mountain {
    width: 400px;
    height: 300px;
    right: -100px;
    animation: mountain 10s linear infinite;
  }
  .mountain1 {
    right: -1200px;
    width: 100px;
    height: 100px;
    animation: mountain1 10s linear infinite;
  }
  .mountain2 {
    right: -600px;
    width: 200px;
    height: 150px;
    animation: mountain2 10s linear infinite;
  }

  @keyframes mountain {
    to {
      transform: translateX(-3000px);
    }
  }
  @keyframes mountain2 {
    to {
      transform: translateX(-3000px);
    }
  }
  @keyframes mountain1 {
    to {
      transform: translateX(-3000px);
    }
  }
  .road {
    width: 500%;
    height: 50%;
    background-color: black;
    position: absolute;
    bottom: 0;
    background-image: url("grid.png");
    background-position: top;
    transform-origin: bottom;
    transform: rotateX(90deg);
    animation: road 1s linear infinite;
  }

  @keyframes road {
    to {
      transform: rotateX(90deg) translateX(-480px);
    }
  }

  .car,
  .car1 {
    width: 300px;
    position: absolute;
    animation: car 0.5s alternate infinite;
  }
  .car1 {
    bottom: 100px;
    left: 200px;
  }

  @keyframes car {
    to {
      transform: translateY(.05px);
    }
  }
}
