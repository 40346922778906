.clientInfoContainer {
  margin: 0 auto;
  margin-top: 84px;
  height: 64vh;
  .header {
    width: 250px;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    padding: 1rem;
    margin: 0 auto;
    margin-bottom: 1rem;
    border-bottom: 2px dotted black;
  }
  .body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: baseline;
    .info, .address {
      display: flex;
      margin: 1rem 0.5rem;
      width: 45%;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
      .fields {
        width: 100%;
        display: flex;
        align-items: center;
        margin: 1rem 0;
        p {
          width: 35%;
          padding: 0.5rem 1rem;
        }
        input {
          flex-grow: 1;
          border: none;
          outline: none;
          padding: 0.5rem 1rem;
        }
      }
    }
  }
  .btn {
    margin: 4rem auto;
    text-align: center;
    button {
      padding: 1rem 3rem;
      background-color: rgb(95, 104, 101);
      font-size: 1.8rem;
      border: none;
      border-radius: 5px;
      &:hover {
        background-color: rgb(0, 226, 0);
        outline: none;
      }
    }
  }
}
