.checkoutContainer {
  margin-top: 83px;
  .header {
    text-align: center;
    font-size: 2.2rem;
    font-weight: bold;
    border-bottom: 1px solid black;
    padding: 1rem;
    margin: 1rem auto;
    width: 240px;
  }
  .mainBody {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 1.5rem;
    .client {
      display: flex;
      width: 50%;
      flex-direction: column;
      align-items: center;
      h1 {
        padding: 1rem;
        margin: 1rem auto;
        font-size: 2.2rem;
        text-align: center;
        width: 100%;
      }
      .authBox {
        border-radius: 10px;
        background-color: rgb(240, 255, 241);
        min-width: 25vw;
        overflow: hidden;
        padding: 5px;
        div {
          .authToggle {
            display: flex;
            height: 3vmax;
            p {
              color: rgba(0, 0, 0, 0.678);
              transition: all 0.5s;
              cursor: pointer;
              display: grid;
              place-items: center;
              width: 100%;
              &:hover {
                color: tomato;
              }
            }
          }
          button {
            background-color: tomato;
            height: 3px;
            width: 50%;
            border: none;
            transition: all 0.5s;
          }
        }
        .google-login {
          width: 84%;
          margin: 2vmax 0 0 2vmax;
          justify-content: center;
        }
        .loginForm,
        .signUpForm {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: auto;
          padding: 0 2vmax;
          justify-content: space-evenly;
          height: 78%;
          transition: all 0.5s;
          div {
            display: flex;
            width: 100%;
            align-items: center;
            input {
              padding: 1vmax 4vmax;
              padding-right: 1vmax;
              width: 100%;
              box-sizing: border-box;
              border: 1px solid rgba(0, 0, 0, 0.267);
              border-radius: 4px;
              outline: none;
            }
            svg {
              position: absolute;
              transform: translateX(1vmax);
              font-size: 1.6vmax;
              color: rgba(0, 0, 0, 0.623);
            }
          }
          .loginBtn,
          .signUpBtn {
            border: none;
            background-color: tomato;
            color: white;
            font: 300 0.9vmax "Roboto";
            width: 100%;
            padding: 0.8vmax;
            cursor: pointer;
            transition: all 0.5s;
            border-radius: 4px;
            outline: none;
            &:hover {
              background-color: rgb(179, 66, 46);
            }
          }
        }
        .loginForm {
          a {
            color: rgba(0, 0, 0, 0.651);
            text-decoration: none;
            align-self: flex-end;
            transition: all 0.5s;
            &:hover {
              color: black;
            }
          }
        }
        .signUpForm {
          transform: translateY(-100%) translateX(-100vmax);
          .registerImage {
            img {
              width: 4vmax;
              border-radius: 100%;
            }
            input {
              display: flex;
              padding: 0%;
              margin-left: 20px;
              &::file-selector-button {
                cursor: pointer;
                width: 100%;
                z-index: 2;
                height: 5vh;
                border: none;
                margin: 0%;
                font: 400 0.8vmax cursive;
                transition: all 0.5s;
                padding: 0 1vmax;
                color: rgba(0, 0, 0, 0.623);
                background-color: rgb(255, 255, 255);
                &:hover {
                  background-color: rgb(235, 235, 235);
                }
              }
            }
          }
        }
        .shiftToNeutral {
          transform: translateX(0%);
        }
        .shiftToLeft {
          transform: translateX(-100%);
        }
        .shiftToNeutralForm {
          transform: translateX(0%) translateY(-100%);
        }
        .shiftToRight {
          transform: translateX(100%);
        }
      }
      .billForm {
        width: 60%;
        .signUpName {
          width: 100%;
          margin-bottom: 1rem;
          input {
            padding: 1rem 2rem;
            width: 100%;
            border: 1px solid rgba(0, 0, 0, 0.267);
            border-radius: 4px;
            outline: none;
          }
        }
        .signUpBtn {
          border: none;
          width: 100%;
          padding: 1rem;
          cursor: pointer;
          transition: all 0.5s;
          border-radius: 4px;
          outline: none;
          &:hover {
            color: white;
            background-color: rgb(46, 179, 57);
          }
        }
      }
      .payment-btn {
        margin: 2rem 0;
        width: 100%;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        .btncontainer {
          width: 185px;
          display: flex;
          align-items: center;
          transition: all 0.5s;
          background-color: rgb(201, 201, 201);
          cursor: pointer;
          border-radius: 5px;
          .interactive-container {
            width: 80px;
            height: 60px;
            background-color: teal;
            position: relative;
            overflow: hidden;
            border-radius: 5px 0px 0px 5px;
            .card {
              width: 40px;
              height: 25px;
              background-color: rgb(135, 250, 215);
              border-radius: 3px;
              position: absolute;
              top: 20px;
              left: 20px;
              transition: all 0.2s ease-in;
              .card-line {
                width: 30px;
                height: 5px;
                background-color: rgb(120, 212, 83);
                border-radius: 5px;
                position: absolute;
                top: 7px;
                left: 5px;
              }
              .card-circles {
                position: absolute;
                top: 17px;
                left: 5px;
                display: flex;
                .card-circle {
                  width: 4px;
                  height: 4px;
                  border-radius: 50%;
                  margin-right: 8px;
                  &:nth-child(1) {
                    background-color: rgb(120, 212, 83);
                  }
                  &:nth-child(2) {
                    background-color: rgb(80, 153, 51);
                  }
                  &:nth-child(3) {
                    background-color: rgb(40, 95, 18);
                  }
                }
              }
            }
            .post {
              width: 35px;
              height: 50px;
              border-radius: 5px;
              background-color: lightgray;
              display: flex;
              flex-direction: column;
              align-items: center;
              position: absolute;
              top: 95px;
              left: 22px;
              transition: transform 0.4s;
              transition-delay: 0.2s;
              .post-line {
                width: 30px;
                height: 10px;
                background-color: gray;
                position: relative;
                &::after {
                  content: "";
                  width: 30px;
                  height: 7px;
                  background-color: rgb(75, 73, 73);
                  position: absolute;
                  bottom: -7px;
                  border-radius: 0px 0px 20px 20px;
                }
              }
              .post-screen {
                width: 27px;
                height: 12px;
                background-color: whitesmoke;
                margin-top: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: teal;
                .post-text {
                  opacity: 0;
                  transition-delay: 1s;
                }
              }
              .post-numbers {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                .post-number {
                  width: 3px;
                  height: 3px;
                  background-color: gray;
                  margin: 3px 3px;
                }
              }
            }
          }
          .text-container {
            font-weight: bold;
            display: flex;
            align-items: center;
            margin: 0 5px;
          }
          &:hover {
            background-color: rgb(31, 56, 104);
            color: white;
            .card {
              animation: moveCard 1.2s cubic-bezier(0.645, 0.045, 0.355, 1) both;
            }
            @keyframes moveCard {
              50% {
                transform: rotate(-90deg) scale(0.8) translateX(90px);
              }
              100% {
                transform: rotate(-90deg) scale(0.8) translateX(18px);
              }
            }
            .post {
              transform: translateY(-87px);
              .post-screen > .post-text {
                opacity: 1;
              }
            }
          }
        }
        button {
          font-weight: bold;
          background-color: rgb(201, 201, 201);
          width: 185px;
          cursor: pointer;
          border: none;
          transition: all 0.5s;
          border-radius: 4px;
          outline: none;
          &:hover {
            background-color: rgb(31, 56, 104);
            color: white;
          }
        }
      }
    }
    .bill {
      text-align: center;
      width: 28%;
      h1 {
        font-size: 2.2rem;
        padding: 1rem;
        margin: 1rem auto;
      }
      .checkout-bill {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .line {
          display: flex;
          margin: 8px 0;
          align-items: center;
          justify-content: space-between;
          background-color: white;
          border: 1px solid rgba(0, 0, 0, 0.267);
          border-radius: 4px;
          div {
            padding: 1rem;
          }
        }
      }
    }
  }
  .checkout-payment {
    margin: 2rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    button {
      background-color: rgb(113, 134, 93);
      color: white;
      padding: 1rem 4rem;
      cursor: pointer;
      border: none;
      transition: all 0.5s;
      border-radius: 4px;
      outline: none;
      &:hover {
        background-color: rgb(31, 56, 104);
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .checkoutContainer {
    padding: 1rem 1.5rem;
    .mainBody {
      .client {
        width: 100%;
        .authBox {
          width: 100%;
          div {
            .authToggle {
              p {
                font-size: 1.6rem;
              }
            }
          }
          .google-login {
            width: 100%;
            margin: 2rem 0;
          }
          .loginForm,
          .signUpForm {
            div {
              input {
                padding: 1.5rem 5rem;
                font: 1.6rem cursive;
              }
              svg {
                font-size: 2.8rem;
              }
            }
            .loginBtn,
            .signUpBtn {
              font: 1.6rem "Roboto";
              padding: 1.8rem;
            }
            .loginForm {
              a {
                font: 500 1.8rem "Gill Sans";
              }
            }
            .signUpForm {
              .registerImage {
                img {
                  width: 8rem;
                  border-radius: 100%;
                }
                input {
                  &::file-selector-button {
                    height: 7vh;
                    font: 400 1.8rem cursive;
                  }
                }
              }
            }
          }
          .loginBtn,
          .signUpBtn {
            font: 300 1.9rem "Roboto";
            padding: 1.8rem;
          }
        }
        .billForm {
          width: 100%;
        }
        .payment-btn {
          .btncontainer,
          button {
            margin: 1rem 0;
            width: 100%;
            height: 50px;
          }
          .btncontainer {
            justify-content: center;
            .interactive-container {
              display: none;
            }
          }
        }
      }
      .bill {
        width: 100%;
      }
    }
  }
}
