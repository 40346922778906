.footer {
  padding: 2vmax 2vmax 0 2vmax;
  background-color: #222121;
  color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  .footerMain {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    line-height: 2vmax;
    justify-content: space-between;
    .qlinks {
      width: 20%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
      h1 {
        border-bottom: 1px solid lightgrey;
        margin-bottom: 1vmax;
      }
    }
    .benifit {
      width: 20%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
    }
    .news {
      width: 30%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
    }
    .help {
      width: 20%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
      a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        p {
          margin: 0 5px;
        }
      }
    }
  }
  .bottom {
    padding: 1vmax;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    p {
      color: #f9f9f975;
    }
    .social {
      display: flex;
      align-items: center;
      justify-content: center;
      .facebook,
      .instagram,
      .twitter {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .circle {
          position: absolute;
          width: 60%;
          height: 60%;
          border: 1px solid #1976d2;
          border-radius: 50%;
          opacity: 0;
          transition: all ease 1s;
        }
        .circle2 {
          width: 80%;
          height: 80%;
          border-width: 2px;
        }

        .circle3 {
          width: 80%;
          height: 80%;
          border: 3px dashed #f9514c;
          transition: transform ease-in 1s, opacity ease-in 0.5s;
        }
        img {
          width: 50%;
          z-index: 1;
        }
      }
      .facebook {
        &:hover {
          .circle {
            opacity: 1;
          }
        }
      }
      .instagram {
        &:hover {
          .circle3 {
            transform: rotate(360deg);
            opacity: 1;
          }
        }
      }
      .twitter {
        transition: all 1s ease-out;
        &:hover {
          transform: rotate(360deg);
          opacity: 1;
        }
      }
    }
  }

  h1 {
    font-size: 2.2rem;
    border-bottom: 1px solid lightgrey;
    margin-bottom: 1vmax;
    padding: 5px;
  }
  .btn,
  input {
    padding: 0.5vmax 1vmax;
    border: none;
    margin-top: 0.5vmax;
    outline: none;
  }
  .btn {
    background-color: #b8b9a3;
    &:hover {
      background-color: chartreuse;
      cursor: pointer;
      transform: scale(1.1);
      transition: all 0.2s ease-out;
    }
  }
  a {
    color: white;
    &:hover {
      color: chartreuse;
      transform: scale(1.3);
      transition: all 0.2s ease-out;
    }
  }
}

@media screen and (max-width: 600px) {
  .footer {
    .footerMain {
      flex-direction: column;
      width: 100%;
      padding: 1vmax;
      .qlinks,
      .benifit,
      .news,
      .help {
        width: 100%;
        padding: 1rem;
        line-height: 2rem;
      }
      a,
      p {
        line-height: 3rem;
      }
    }
  }
}
