.pricingCard {
  border: 1px solid rgba(207, 207, 207, 0.589);
  border-radius: 7px;
  padding: 1.5rem 0;
  width: 40rem;
  display: flex;
  margin: 3vmax 1vmax;
  transition: all 0.5s;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  background-color: white;
  .price-title {
    font-size: 1.6rem;
    margin-bottom: 0.5vmax;
    border-bottom: 2px solid gray;
    padding: 1vmax;
  }
  .pricing-content {
    width: 100%;
    line-height: 4rem;
    text-align: center;
    flex-grow: 1;
    margin-bottom: 1vmax;
  }
  .pricingFooter {
    padding: 1vmax;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    line-height: 2rem;
    color: dimgray;
    background-color: rgb(245, 243, 238);
    margin-bottom: -1.5rem;
  }
  &:hover {
    p {
      font-size: 1.6rem;
    }
    box-shadow: 0 0 5px rgba(15, 15, 15, 0.26);
    transform: translateY(-1vmax) scale(1.1);
    transition: all 0.2s ease-in;
    background-color: rgb(240, 255, 241);
  }
}
@media screen and (max-width: 600px) {
  .pricingCard {
    width: 100%;
  }
}
