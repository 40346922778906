.serviceContainer {
  max-width: 1400px;
  margin: 84px auto;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 100%;
  }
  h1 {
    font-size: 3rem;
    border-bottom: 1px solid rgb(172, 169, 169);
    padding: 2rem;
    padding-bottom: 0;
    margin-bottom: 2rem;
  }
  p {
    padding: 1rem 2rem;
    line-height: 2.5rem;
  }
}
@media screen and (max-width: 600px) {
  .serviceContainer {
    width: 100%;
    padding: 1rem 1.5rem;
    p {
      padding: 1rem 0;
    }
  }
}
