.mobileMenu {
  width: 100vw;
  background: linear-gradient(to top left, #dad3d3, #7dd56f);
  z-index: 98;
  position: fixed;
  top: 0;
  display: flex;
  transition: 0.3s;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  padding: 1rem;
  .image {
    img {
      width: 60px;
    }
  }
  .line-container {
    margin: 10px;
    width: 40px;
    height: 30px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .line {
      width: 100%;
      height: 3px;
      background-color: black;
      border-radius: 10px;
      transition: transform 1s ease, opacity 0.5s ease;
      transform-origin: 0;
    }
    .line-1 {
      transform: rotate(45deg);
    }

    .line-2 {
      opacity: 0;
    }

    .line-3 {
      transform: rotate(-45deg);
    }
  }
}
.menu-container {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  background-color: white;
  padding: 3rem;
  border-radius: 5px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 10;
  span {
    .MuiSvgIcon-root {
      font-size: 3rem;
    }
  }
}

.show {
  a {
    margin-bottom: 20px;
  }
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: flex-end;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  z-index: 5;
}
.hidden {
  display: none;
}
