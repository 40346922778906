.adminContainer{
  margin-top: 84px;
  .menuList {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin: 0.5vmax 0;
    padding: 1rem;
    a,
    span {
      margin-left: 2vmax;
      padding: 1rem 2.5rem;
      background-color: #c4ebce;
      border: none;
      outline: none;
      border-radius: 5px;
      font-weight: bold;
      &:hover {
        transform: translateY(-0.2vmax);
        transition: all 0.3zs;
      }
    }
    a {
      &:hover {
        background-color: rgb(126, 255, 126);
      }
    }
    span {
      padding: 1rem 3.5rem;
      &:hover {
        background-color: rgb(253, 239, 40);
      }
    }
  }
  h1{
    font-size: 3rem;
    text-align: center;
    padding: 2rem;
  }
  .chart{
    margin: 5rem auto;
    width: 80%;
  }
}