.bookService {
  text-align: center;
  .bookServiceHeader {
    font-size: 1.5rem;
    padding: 1vmax;
    font-style: italic;
  }
  .bookServiceContent {
    line-height: 2vmax;
    .button-common {
      width: 200px;
      padding: 1rem;
      margin-bottom: 1rem;
      background-color: #d0d0d0;
      border: none;
      outline: none;
      border-radius: 5px;
      &:hover {
        background-color: green;
        color: white;
      }
    }
    .setbookingDateTime {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      .date-time-picker {
        display: flex;
        padding: 1rem;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        input,
        select {
          width: 200px;
          margin: 1rem 2rem;
          padding: 0.5rem;
          text-align: center;
        }
      }
      .react-datepicker__day-name,
      .react-datepicker__day,
      .react-datepicker__time-name {
        color: #000;
        display: inline-block;
        width: 4.1rem;
        line-height: 3.7rem;
        text-align: center;
        margin: 0.166rem;
      }
      .react-datepicker__current-month {
        font-size: 1.3rem;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .bookService {
    .bookServiceContent {
      .pagebtn {
        width: 100%;
        padding: 1rem;
        .button-common {
          width: 100%;
        }
      }
      .setbookingDateTime {
        .date-time-picker {
          input,
          select {
            margin: 1rem;
          }
        }
      }
    }
  }
}
